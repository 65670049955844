var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',{attrs:{"title":"Clients Testimonials"}},[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeatAgain.apply(null, arguments)}}},_vm._l((_vm.testimonials),function(testimonial,index){return _c('b-row',{key:testimonial.id,ref:"row",refInFor:true,attrs:{"id":testimonial.id}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Client Name English")]),_c('validation-provider',{attrs:{"vid":'clientNameEn' + testimonial.id,"rules":"required","name":"Client Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Client Name","rows":"3"},model:{value:(testimonial.clientNameEn),callback:function ($$v) {_vm.$set(testimonial, "clientNameEn", $$v)},expression:"testimonial.clientNameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Client Name Arabic")]),_c('validation-provider',{attrs:{"vid":'clientNameAr' + testimonial.id,"rules":"required","name":"Client Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Client Name","rows":"3"},model:{value:(testimonial.clientNameAr),callback:function ($$v) {_vm.$set(testimonial, "clientNameAr", $$v)},expression:"testimonial.clientNameAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Content English")]),_c('validation-provider',{attrs:{"vid":'contentEn' + testimonial.id,"rules":"required","name":"Content English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Content English","rows":"3"},model:{value:(testimonial.contentEn),callback:function ($$v) {_vm.$set(testimonial, "contentEn", $$v)},expression:"testimonial.contentEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Content Arabic")]),_c('validation-provider',{attrs:{"vid":'contentAr' + testimonial.id,"rules":"required","name":"Content Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","state":errors.length > 0 ? false:null,"placeholder":"Content Arabic","rows":"3"},model:{value:(testimonial.contentAr),callback:function ($$v) {_vm.$set(testimonial, "contentAr", $$v)},expression:"testimonial.contentAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"5","xl":"5"}},[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("Before")]),_c('b-form-file',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.selectImageBefore($event, testimonial)}}}),(!testimonial.imageBefore)?_c('div',[_c('validation-provider',{attrs:{"vid":'imageBefore' + testimonial.id,"rules":"required","name":"Before Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"5","xl":"5"}},[_c('label',{attrs:{"for":"wildcard"}},[_vm._v("After")]),_c('b-form-file',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.selectImageAfter($event, testimonial)}}}),(!testimonial.imageAfter)?_c('div',[_c('validation-provider',{attrs:{"vid":'imageAfter' + testimonial.id,"rules":"required","name":"After Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"5","xl":"5"}},[(testimonial.imageBefore)?_c('div',[_c('b-img',{staticStyle:{"height":"200px"},attrs:{"fluid":"","src":testimonial.imageBefore,"alt":"Card image cap"}})],1):_vm._e()]),_c('b-col',{staticClass:"mb-1",attrs:{"md":"5","xl":"5"}},[(testimonial.imageAfter)?_c('div',[_c('b-img',{staticStyle:{"height":"200px"},attrs:{"fluid":"","src":testimonial.imageAfter,"alt":"Card image cap"}})],1):_vm._e()])],1),_c('b-row',[_c('b-col',{staticClass:"mt-2 mb-1",attrs:{"md":"4","xl":"5"}},[_c('validation-provider',{attrs:{"vid":'isVisible' + testimonial.id,"rules":"required","name":"Is Visible"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{model:{value:(testimonial.isVisible),callback:function ($$v) {_vm.$set(testimonial, "isVisible", $$v)},expression:"testimonial.isVisible"}},[_vm._v(" Is Visible ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }