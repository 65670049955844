<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <span>Designers</span>
      </template>

      <TestimonialsDesigners ref="designers" />
    </b-tab>

    <b-tab>
      <template #title>
        <span>Clients</span>
      </template>

      <TestimonialsClients ref="clients" />
    </b-tab>
    <!-- New Tab Button (Using tabs-end slot) -->
    <template #tabs-end>
      <div class="ml-auto">
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              right
              @click.prevent="publish"
            >
              Publish
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TestimonialsDesigners from './DesignersTestimonials.vue'
import TestimonialsClients from './ClientsTestimonials.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
    TestimonialsDesigners,
    TestimonialsClients
  },
  directives: {
    Ripple,
  },
  methods: {
    async publish() {
      try {
        await Promise.all([
          this.$refs.designers.save(true),
          this.$refs.clients.save(true)])
        await this.$http.post('/testimonials/publish')

        this.showToast('Published Successfully')
       
      } catch (error) {}
    },

    showToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    }
  },
}
</script>
