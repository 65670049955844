<template>
  <validation-observer ref="validationObserver">
    <b-card-form title="Clients Testimonials">
      <div>
        <div>
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeatAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(testimonial, index) in testimonials"
              :id="testimonial.id"
              :key="testimonial.id"
              ref="row"
            >
              <b-col cols="12">
                <b-row>
                  <!-- Client name english -->
                  <b-col
                      md="4"
                      xl="5"
                  >
                    <label for="textarea-default">Client Name English</label>
                    <validation-provider
                        #default="{ errors }"
                        :vid="'clientNameEn' + testimonial.id"
                        rules="required"
                        name="Client Name"
                    >
                      <b-form-input
                          id="textarea-default"
                          v-model="testimonial.clientNameEn"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Client Name"
                          rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <!-- Clients name arabic  -->
                  <b-col
                      md="4"
                      xl="5"
                  >
                    <label for="textarea-default">Client Name Arabic</label>
                    <validation-provider
                        #default="{ errors }"
                        :vid="'clientNameAr' + testimonial.id"
                        rules="required"
                        name="Client Name"
                    >
                      <b-form-input
                          id="textarea-default"
                          v-model="testimonial.clientNameAr"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Client Name"
                          rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12">
                <b-row class="mt-2">
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <label for="textarea-default">Content English</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'contentEn' + testimonial.id"
                      rules="required"
                      name="Content English"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        v-model="testimonial.contentEn"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Content English"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                    dir="rtl"
                  >
                    <label for="textarea-default">Content Arabic</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'contentAr' + testimonial.id"
                      rules="required"
                      name="Content Arabic"
                    >
                      <b-form-textarea
                        id="textarea-default"
                        v-model="testimonial.contentAr"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Content Arabic"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col
                      md="5"
                      xl="5"
                      class="mb-1"
                  >
                    <label for="wildcard">Before</label>
                    <b-form-file type="file" @change="selectImageBefore($event, testimonial)" accept="image/*"/>
                    <div v-if="!testimonial.imageBefore">
                      <validation-provider
                          #default="{ errors }"
                          :vid="'imageBefore' + testimonial.id"
                          rules="required"
                          name="Before Image"
                      >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                      md="5"
                      xl="5"
                      class="mb-1"
                  >
                    <label for="wildcard">After</label>
                    <b-form-file type="file" @change="selectImageAfter($event, testimonial)" accept="image/*"/>
                    <div v-if="!testimonial.imageAfter">
                      <validation-provider
                          #default="{ errors }"
                          :vid="'imageAfter' + testimonial.id"
                          rules="required"
                          name="After Image"
                      >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col
                      md="5"
                      xl="5"
                      class="mb-1"
                  >
                    <div v-if="testimonial.imageBefore">
                      <b-img
                          fluid
                          style="height: 200px"
                          :src="testimonial.imageBefore"
                          alt="Card image cap"
                      />
                    </div>
                  </b-col>
                  <b-col
                      md="5"
                      xl="5"
                      class="mb-1"
                  >
                    <div v-if="testimonial.imageAfter">
                      <b-img
                          fluid
                          style="height: 200px"
                          :src="testimonial.imageAfter"
                          alt="Card image cap"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="4"
                    xl="5"
                    class="mt-2 mb-1"
                  >
                    <validation-provider
                        #default="{ errors }"
                        :vid="'isVisible' + testimonial.id"
                        rules="required"
                        name="Is Visible"
                    >
                      <b-form-checkbox
                          v-model="testimonial.isVisible"
                      >
                        Is Visible
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add New</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save(false)"
            >
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
  BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import UUID from '@/libs/uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import vSelect from 'vue-select';
import store from '@/store';
import base64Encode from "@/libs/UploadFilesService";

export default {
  components: {
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardForm,
    BImg,
    BFormTextarea,
    vSelect
  },

  directives: {
    Ripple,
  },

  mixins: [heightTransition],

  data() {
    return {
      testimonials: [],
      options: [],
      required,
      data: {},
      isLoading: false
    }
  },

  methods: {
    selectImageBefore(event, item) {
      const { files } = event.target;

      if (files && files[0]) {
        const index = this.testimonials.findIndex((testimonial) => testimonial.id === item.id);

        base64Encode(files[0])
            .then(value => {
              const config = { headers: { 'Content-Type': 'application/json' } }
              this.$http.post('/uploads', JSON.stringify(value), config)
                  .then(response => {
                    this.testimonials[index].imageBefore = response.data;

                    this.$forceUpdate();
                  })
            })
      }
    },

    async selectImageAfter(event, item) {
      const { files } = event.target;

      if (files && files[0]) {
        const index = this.testimonials.findIndex((testimonial) => testimonial.id === item.id);

        await base64Encode(files[0])
            .then(value => {
              const config = { headers: { 'Content-Type': 'application/json' } }
              this.$http.post('/uploads', JSON.stringify(value), config)
                  .then(response => {
                    this.testimonials[index].imageAfter = response.data;

                    this.$forceUpdate();
                  })
            })
      }
    },

    repeatAgain() {
      this.testimonials.push({
        id: UUID(),
        isVisible: false,
        options: [...this.options],
        isLoading: false
      })
    },
    removeItem(index) {
      this.testimonials.splice(index, 1)
    },
    save(isPublish) {
      const valid = this.$refs.validationObserver
        .validate()
        .then(success =>  {
          if (success) {
            const data = { testimonials: this.testimonials }
            Promise.all([
              this.$http.post('/testimonials/clients', data)]).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Check the feilds validations above to be able to save!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          return success
        })
      return valid
    },
  },

  async created() {
    const response = await this.$http.get('/Testimonials/clients');

    this.testimonials = response.data;
  }
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
